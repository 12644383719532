import React, { useState } from "react";
import { observer } from "mobx-react";
import { diffWords, diffChars } from "diff";
import { AISuggestionTab } from "~/store/tabs";
import { Check, Undo } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { AISuggestionEntry } from "~/store/ai";

interface DiffChunk {
  count: number;
  added: boolean;
  removed: boolean;
  value: string;
  originalValue?: string;
}

interface GroupedDiffChunk extends DiffChunk {
  changed?: boolean;
}

function groupChanges(diff: DiffChunk[]): GroupedDiffChunk[] {
  const result: GroupedDiffChunk[] = [];
  let i = 0;
  while (i < diff.length) {
    if (diff[i].removed && i + 1 < diff.length && diff[i + 1].added) {
      result.push({
        count: diff[i].count + diff[i + 1].count,
        added: false,
        removed: false,
        changed: true,
        value: diff[i + 1].value,
        originalValue: diff[i].value,
      });
      i += 2;
    } else {
      result.push({ ...diff[i] });
      i++;
    }
  }
  return result;
}

function DiffView({ before = "", after = "" }) {
  const diff = diffWords(before, after);

  // modify to add changed part (removed followed by added)
  const groupedDiff = groupChanges(diff);

  console.log("original", diff);
  console.log("grouped", groupedDiff);

  return (
    <div className="whitespace-pre-wrap">
      {groupedDiff.map((part, index) => {
        if (part.changed) {
          // diff by chars to check if this is a small change
          const charDiff = diffChars(part.originalValue, part.value);
          let content = <span className="font-bold">{part.value}</span>;
          const lenDiff = Math.abs(
            part.originalValue.length - part.value.length
          );
          const hasSpace =
            part.value.includes(" ") || part.originalValue.includes(" ");

          if (
            lenDiff < 2 &&
            charDiff.length <= 4 &&
            charDiff.length > 1 &&
            part.value.length > 2 &&
            !hasSpace
          ) {
            content = (
              <>
                {charDiff
                  .filter((c) => !c.removed)
                  .map((char, index) => (
                    <span
                      key={index}
                      className={char.added ? "font-bold underline" : ""}
                    >
                      {char.value}
                    </span>
                  ))}
              </>
            );
          }
          return (
            <Tooltip title={part.originalValue}>
              <span
                key={index}
                className="bg-purple-200 text-purple-800 rounded"
              >
                {content}
              </span>
            </Tooltip>
          );
        }
        if (part.added) {
          return (
            <span
              key={index}
              className="bg-green-200 font-bold text-green-800 rounded"
            >
              {part.value}
            </span>
          );
        }
        if (part.removed) {
          return (
            <span
              key={index}
              className="bg-red-200 text-red-800 rounded line-through"
            >
              {part.value}
            </span>
          );
        }
        return <span key={index}>{part.value}</span>;
      })}
    </div>
  );
}

const EditOnClick = ({
  suggestion,
  children,
}: {
  suggestion: AISuggestionEntry;
  children: React.ReactNode;
}) => {
  const [editing, setEditing] = useState(false);
  const handleClick = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <div className="relative">
        <textarea
          value={suggestion.value}
          onChange={(e) => suggestion.setValue(e.target.value)}
          className="w-full rounded p-1 border bg-slate-50 dark:bg-slate-950 h-full bg-transparent outline-none"
          onBlur={() => setEditing(false)}
          autoFocus
        />
      </div>
    );
  }

  return (
    <div className="relative p-1" onClick={handleClick}>
      {children}
    </div>
  );
};
export const AiSuggestionRenderer = observer(
  ({ tab }: { tab: AISuggestionTab; scrollTop: number }) => {
    const suggestions = tab.suggestions;

    return (
      <div className="overflow-y-auto h-[calc(100vh-73px)]">
        <div className="shadow z-20 rounded-lg flex sticky top-0 justify-between items-center gap-2 border-b border-gray-200 bg-white/60 backdrop-blur-md">
          <h2 className="p-2 font-semibold inline-flex m-2">AI Suggestions</h2>
          <span>{tab.file.name}</span>
          <div className="flex gap-2 m-1 my-2">
            {/* <button className="bg-gray-400/10 p-2 rounded-lg inline-flex">
              Reject All
            </button> */}
            <button
              onClick={() => tab.acceptAll()}
              className="bg-gray-400/10 p-2 rounded-lg bg-purple-100 inline-flex"
            >
              Accept All
            </button>
          </div>
        </div>
        <div className="">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/5">
                  Key
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/5">
                  Before
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-2/5">
                  Suggestion
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {suggestions.map((suggestion) => (
                <tr key={suggestion.key}>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t">
                    {suggestion.key}
                  </td>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t">
                    {suggestion.originalValue || (
                      <span className="opacity-50">N/A</span>
                    )}
                  </td>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t">
                    <EditOnClick suggestion={suggestion}>
                      <DiffView
                        before={suggestion.originalValue}
                        after={suggestion.value}
                      />
                    </EditOnClick>
                  </td>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t">
                    {suggestion.applied ? (
                      <button
                        onClick={() => suggestion.revert()}
                        className="hover:bg-gray-100 font-semibold p-2 px-3 rounded opacity-80 text-sm flex gap-1 items-center"
                      >
                        <Undo /> Revert
                      </button>
                    ) : (
                      <div className="flex gap-2">
                        {/* <button className="hover:bg-gray-100 font-semibold p-2 px-3 rounded opacity-80 text-sm">
                          <CloseRounded />
                        </button> */}
                        <button
                          onClick={() => suggestion.apply()}
                          className="hover:bg-green-200 font-semibold p-2 px-3 rounded"
                        >
                          <Check />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);
