import "./styles/index.css";

import React, { useEffect } from "react";
import "normalize.css";
import * as Sentry from "@sentry/react";
import { render } from "react-dom";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { useAsync } from "react-use";

// notifications
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// Store
import { configure } from "mobx";
import { observer } from "mobx-react";

// Routing
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
  redirect,
} from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";
import { StylesProvider } from "@mui/styles";

import Loading from "~/project/Loading";
import store from "./store";
import "./store/autosave";

// Routes
// import Repo from './repo';
import Project from "./project";

import RepoList from "./repoList";
import Nothing from "./pages/Nothing";
import Login from "./pages/Login";
import ErrorBoundary from "./components/ErrorBoundary";
import SwUpdate from "./components/SwUpdate";
import pkg from "../package.json";
import "./service-worker";

declare global {
  interface Window {
    DEPLOY_ENV: string;
  }
}

// first thing. init sentry
Sentry.init({
  dsn:
    process.env.NODE_ENV === "production"
      ? "https://ed4a631c7fd34acabec6d2bac1c854ed@sentry.io/235865"
      : null,
  release: `ted@${pkg.version}`,
  environment:
    window.DEPLOY_ENV ||
    process.env.DEPLOY_ENV ||
    process.env.NODE_ENV ||
    "local",
  integrations: [],
  tracesSampleRate: 0.2,
});

// mobx plz be nice. can not rewrite the whole thing
configure({ enforceActions: "never" });

axios.defaults.baseURL = "https://gitlab.hydra-newmedia.cloud/api/v4";

const queryClient = new QueryClient();

const userFetchOp = store.ensureUser();

if ("paintWorklet" in CSS) {
  (CSS as any).paintWorklet.addModule(
    "https://unpkg.com/inverted-corners/lib/inverted-corners-worklet.min.js"
  );
}

const RequireAuth = observer(({ children }) => {
  const { user } = store;
  const location = useLocation();
  const fetchState = useAsync(() => userFetchOp);

  if (fetchState.loading) return <Loading message="Loading TED" />;

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
});

async function requireUser() {
  await store.ensureUser().catch(console.log);
  if (!store.userIsAuthenticated) {
    throw redirect("/login");
  }
  return store.user;
}

const ThemeVariantProvider = observer(({ children }) => {
  const { themeVariant } = store.userSettings;

  const theme = createTheme({
    palette: {
      // primary: { main: '#2196f3' },
      // secondary: { main: '#E91E63' },
      mode: themeVariant,
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <RepoList />,
    async loader({}) {
      await requireUser();

      if (!store.projects.hasProjects) {
        store.projects.fetchProjects();
      }

      // we get the projects using mobx, not the loader
      return null;
    },
  },
  {
    path: "/repo/:id/*",
    element: (
      <RequireAuth>
        <Project />
      </RequireAuth>
    ),
    async loader({ params }) {
      await requireUser();
      await store.openProject(params.id);

      return store.currentProject;
    },
  },
  {
    path: "*",
    element: <Nothing />,
  },
]);

const App = () => {
  useEffect(() => {
    // redirect from hash routes if needed
    const hash = window.location.hash;
    if (hash && hash.startsWith("#/") && window.location.pathname === "/") {
      window.location.hash = "";
      window.location.pathname = hash.slice(1);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <ThemeVariantProvider>
          <ErrorBoundary>
            <RouterProvider router={router} />
          </ErrorBoundary>
          <ToastContainer
            theme={store.userSettings.themeVariant}
            position="bottom-center"
          />
          <SwUpdate />
        </ThemeVariantProvider>
      </StylesProvider>
    </QueryClientProvider>
  );
};

render(<App />, document.getElementById("app"));

if ("hot" in module && module.hot) {
  (module.hot as any).accept();
}
